<template>
  <div class="EduHome">
    <EduHeader @setGoToUrl="setGoToUrl" />
    <main class="containerMain">
      <NavInfo @setGoToUrl="setGoToUrl" />
      <div
        v-if="examDetails"
        class="containerMainRight"
        style="padding-left: 60px">
        <div class="NewTopicCenter">
          <div class="NewTopicCenterTitle">
            <div class="title-fl">
              {{ examDetails.title }} <span>【{{ method }}】</span>
            </div>
            <div class="NewTopicCenter-tItem">
              <div class="NewTopicCenter-time">
                倒计时：<span>{{ countdown }}</span>
              </div>
            </div>
          </div>
          <div class="NewTopicCenter-mt35">
            <div class="NewTopicCenter-tas">
              <div class="tas-fl" ref="tasCenter">
                <dl ref="tasItem">
                  <dd
                    v-for="(
                      sectionItem, sectionIndex
                    ) in examDetails.examSectionVOList"
                    :key="sectionIndex"
                    :class="{ on: sectionIndex == active }"
                    @click="setActive(sectionIndex, 0)">
                    {{ sectionItem.title }}
                  </dd>
                  <!-- <dd class="on">多选题</dd>
                  <dd>计算分析</dd>
                  <dd>综合题</dd> -->
                </dl>
              </div>
              <div
                class="tasBut"
                style="float: left"
                v-if="offsetWidth2 > offsetWidth1">
                <span style="color: #666" v-if="intInfo === 0">&lt;</span>
                <span v-else @click="tasButFn(1)">&lt;</span>
                <span v-if="intInfo < wInt - 1" @click="tasButFn(2)">&gt;</span>
                <span style="color: #666" v-else>&gt;</span>
              </div>
              <div class="tas-fr" v-if="examDetails">
                {{ examDetails.examSectionVOList[active].title }}共{{
                  examDetails.examSectionVOList[active].examSectionTopicVOList
                    .length
                }}题 总分{{ scores }}分 第{{ topicIndex + 1 }}题
                <!--questionCount + -->
                {{
                  examDetails.examSectionVOList[active].examSectionTopicVOList[
                    topicIndex
                  ].score
                }}分
              </div>
            </div>
            <div class="NewTopicCenter-content" :class="{ borC: active === 0 }">
              <dl>
                <dd>
                  <div class="newTopicItemTitle">
                    <span
                      v-for="(topicItem, sectionIndex) in examDetails
                        .examSectionVOList[active].examSectionTopicVOList"
                      :key="sectionIndex"
                      @click="clickQuestion(sectionIndex)"
                      :class="{ topicIndex: sectionIndex == topicIndex }"
                      >{{ sectionIndex + 1 }}</span
                    >
                    <!-- questionCount +  <span>2</span>
                    <span>3</span> -->
                  </div>
                </dd>
                <dd>
                  <div
                    v-for="(
                      sectionItem, sectionIndex
                    ) in examDetails.examSectionVOList"
                    :key="sectionIndex">
                    <div
                      v-for="(
                        topicItem, topicVOIndex
                      ) in sectionItem.examSectionTopicVOList"
                      :key="topicVOIndex">
                      <topic
                        v-show="
                          active == sectionIndex && topicVOIndex == topicIndex
                        "
                        :topicData="topicItem.topicVO"
                        :isShowQuestionTitle="false"
                        presentation="TILL_ALL"
                        @set-topic-answers="setTopicAnswers"
                        :topicIndex="
                          getQuestionCount(sectionIndex) + topicVOIndex
                        "
                        :topicVOIndex="topicVOIndex"
                        :isAnswerSubmit="isAnswerSubmit"
                        :isTest="true"
                        refKind="Exam"
                        :refId="examDetails.id"
                        arrowType="favorite"
                        wrongTopicRefKind="Exam"
                        :wrongTopicRefId="examDetails.categoryId" />
                      <!--getQuestionCount(sectionIndex) + -->
                    </div>
                  </div>
                  <!-- <div
                    v-if="
                      examDetails.examSectionVOList[active]
                        .examSectionTopicVOList != null
                    "
                  >
                    <topic
                      :topicData="
                        examDetails.examSectionVOList[active]
                          .examSectionTopicVOList[topicIndex].topicVO
                      "
                      presentation="TILL_ALL"
                      @set-topic-answers="setTopicAnswers"
                      :topicIndex="questionCount + topicIndex"
                      :isAnswerSubmit="isAnswerSubmit"
                      refKind="Exam"
                      :refId="examDetails.id"
                      arrowType="favorite"
                      :isShowSubjecTitle="false"
                    />
                  </div> -->
                </dd>
              </dl>
              <div
                style="
                  width: 100%;
                  border: 0px solid red;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 40px;
                ">
                <div
                  v-if="active > 0 || (active == 0 && topicIndex != 0)"
                  class="purchaseItemButton"
                  @click="preTopic">
                  上一题
                </div>
                <div
                  v-if="
                    examDetails.examSectionVOList.length - 1 != active ||
                    (examDetails.examSectionVOList.length - 1 == active &&
                      examDetails.examSectionVOList[active]
                        .examSectionTopicVOList.length -
                        1 !=
                        topicIndex)
                  "
                  style="margin-left: 30px"
                  class="purchaseItemButton"
                  @click="nextTopic">
                  下一题
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 60px;
            ">
            <div
              v-if="!isAnswerSubmit"
              class="purchaseItemButton"
              @click="setGoToUrl('')">
              提交
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- <div class="NewTopicShow">
      <div class="NewTopicInfo">
        <div class="close"></div>
        <div class="infoTitle">
          <div class="title">选择适合您的VIP计划</div>
          <div class="title-font">购买多多，优惠多多</div>
        </div>
        <div class="NewTopic-ul">
          <ul>
            <li>
              <el-row>
                <el-col :span="5" class="lh50bf">
                  <label>
                    <input type="radio" name="rm1">
                    1个视频
                  </label>
                </el-col>
                <el-col :span="7">
                  <div class="font-1">购买本节</div>
                  <div class="font-time">有效期：2022/04/30</div>
                </el-col>
                <el-col :span="7" class="lh50">
                  ￥10
                </el-col>
                <el-col :span="5">
                  <div class="gmBut">立即购买</div>
                </el-col>
              </el-row>
            </li>
            <li>
              <el-row>
                <el-col :span="5" class="lh50bf">
                  <label>
                    <input type="radio" name="rm1">
                    1个视频
                  </label>
                </el-col>
                <el-col :span="7">
                  <div class="font-1">购买本节</div>
                  <div class="font-time">有效期：2022/04/30</div>
                </el-col>
                <el-col :span="7" class="lh50">
                  ￥10
                  <span>优惠前￥250</span>
                </el-col>
                <el-col :span="5">
                  <div class="gmBut">立即购买</div>
                </el-col>
              </el-row>
            </li>
            <li>
              <el-row>
                <el-col :span="5" class="lh50bf">
                  <label>
                    <input type="radio" name="rm1">
                    10个视频
                  </label>
                </el-col>
                <el-col :span="7">
                  <div class="font-1">购买本节</div>
                  <div class="font-time">有效期：2022/04/30</div>
                </el-col>
                <el-col :span="7" class="lh50">
                  ￥10
                  <span>优惠前￥250</span>
                </el-col>
                <el-col :span="5">
                  <div class="gmBut">立即购买</div>
                </el-col>
              </el-row>
            </li>
            <li>
              <el-row>
                <el-col :span="5" class="lh50bf">
                  <label>
                    <input type="radio" name="rm1">
                    10个视频
                  </label>
                </el-col>
                <el-col :span="7">
                  <div class="font-1">购买本节</div>
                  <div class="font-time">有效期：2022/04/30</div>
                </el-col>
                <el-col :span="7" class="lh50">
                  ￥10
                  <span>优惠前￥250</span>
                </el-col>
                <el-col :span="5">
                  <div class="gmBut">立即购买</div>
                </el-col>
              </el-row>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="showFile" v-if="fileShow">
      <div class="showFile-info">
        <i @click="fileShow = false"><img src="~@/assets/icon-gb.png" /></i>
        <div class="showFile-info-font">
          <span>是否提交</span>整个试卷?<br />模拟考试时间,<span
            >一经提交,不得修改</span
          >
        </div>
        <div class="showFile-info-button" @click="continueToAnswer()">
          继续作答
        </div>
        <div
          class="showFile-info-button"
          style="margin-top: 20px"
          @click="confirmSubmission('NewTopic')">
          确认提交
        </div>
        <div v-if="isGoTo" class="showFile-info-h">
          <span @click="goTo()">离开</span>
        </div>
      </div>
    </div>

    <div class="showFile" v-if="noPayFileShow">
      <div class="showFile-info">
        <i @click="noPayFileShow = false"
          ><img src="~@/assets/icon-gb.png"
        /></i>
        <div class="showFile-info-font">您未购买此试卷或试卷已过期~</div>
        <div class="showFile-info-button" @click="toExamination">
          去历年考卷
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EduHeader from '@/components/EduHeader';
import NavInfo from '@/components/NavInfo';
import { getExamDetails } from '@/api/examController';
import Topic from '@/components/Question/Topic.vue';
import { secondsToTimeStr } from '@/utils/validate';
import { examTopicResultSubmit } from '@/api/memberTopicResultController';
import { postVisitsExam } from '@/api/memeberExamVisits';
import { getMemberResourceStatus, getExpiration } from '@/api/memberController';
export default {
  name: 'NewTopic',
  data() {
    return {
      activeName: 'Teaching',
      show: false,
      info: '对',
      examId: -1,
      examDetails: null,
      active: 0,
      topicIndex: 0,
      isAnswerSubmit: false,
      intInfo: 0,
      offsetWidth1: 0,
      offsetWidth2: 0,
      wInt: 0,
      questionCount: 0,
      examAnswers: [],
      countdownTimeout: -1,
      countdown: 0,
      spendTimeout: -1,
      scores: 0,
      fileShow: false,
      noPayFileShow: false,
      isGoTo: false,
      goToUrl: '',
      courseId: null,
      method: '模考',
    };
  },
  beforeDestroy() {
    window.clearTimeout(this.countdownTimeout);
    window.clearTimeout(this.spendTimeout);
  },
  created() {
    //alert(this.$store.state.COURSE_ID)
    const id = this.$route.params && this.$route.params.id;
    this.examId = id;
    const method = this.$route.query && this.$route.query.method;
    if (method == 'PRACTICE_QUIZ' || method == 'QUIZ') {
      this.method = '模考';
    } else if (method == 'PRACTICE_TEST' || method == 'TEST') {
      this.method = '测验';
    }
    this.getExamDetails();
  },
  methods: {
    toExamination() {
      this.$router.push({
        path: '/ExaminationCategory',
      });
    },
    goTo() {
      if (this.goToUrl == 'LOGOUT') {
        this.$store.commit('del_token');
        this.$router.push({ path: '/' });
      } else {
        this.$router.push({
          path: this.goToUrl,
        });
      }
    },
    setGoToUrl(_url) {
      this.goToUrl = _url;
      if (this.examDetails != null) {
        this.fileShow = true;
        if (_url != '') {
          this.isGoTo = true;
        }
      } else {
        this.goTo();
      }
    },
    continueToAnswer() {
      this.fileShow = false;
      this.isGoTo = false;
    },
    confirmSubmission() {
      this.continueToAnswer();
      this.answerSubmit();
    },
    tasButFn(row) {
      if (row === 1) {
        this.intInfo--;
      }
      if (row === 2) {
        this.intInfo++;
      }
      this.$refs.tasItem.style.left =
        -(this.intInfo * this.offsetWidth1) + 'px';
    },
    setTopicAnswers(practiceIndex, topicIndex, topicAnswers) {
      this.examAnswers[topicIndex] = topicAnswers;
    },
    setActive(active, topicIndex) {
      this.active = active;
      this.questionCount = 0;
      this.topicIndex = topicIndex;
      for (let i = 0; i < this.active; i++) {
        this.questionCount +=
          this.examDetails.examSectionVOList[i].examSectionTopicVOList.length;
      }
      this.scores = 0;
      let examSectionTopicVOList =
        this.examDetails.examSectionVOList[active].examSectionTopicVOList;
      for (let i = 0; i < examSectionTopicVOList.length; i++) {
        this.scores += examSectionTopicVOList[i].score;
      }
      this.setTopicSpend();
    },
    getQuestionCount(active) {
      let questionCount = 0;
      for (let i = 0; i < active; i++) {
        questionCount +=
          this.examDetails.examSectionVOList[i].examSectionTopicVOList.length;
      }
      return questionCount;
    },
    clickQuestion(topicIndex) {
      this.topicIndex = topicIndex;
    },
    getExamDetails() {
      getExamDetails(this.examId).then((res) => {
        if (res.code == 20000) {
          if (res.data.price != 0) {
            //this.getMemberResourceStatus(res);
            this.getExpiration(res);
          } else {
            this.initExam(res);
          }
        } else {
          this.$baseMessage(res.data.msg, 'error', 'vab-hey-message-error');
        }
      });
    },
    getExpiration(resExamDetail) {
      getExpiration({ categoryId: parent.CATEGORY_ID }).then((res) => {
        if (res.code == 20000) {
          if (res.data != '') {
            this.initExam(resExamDetail);
          } else {
            this.noPayFileShow = true;
          }
        } else {
          this.$baseMessage(res.data.msg, 'error', 'vab-hey-message-error');
        }
      });
    },
    getMemberResourceStatus(resExamDetail) {
      getMemberResourceStatus({ refId: this.examId, refKind: 'EXAM' }).then(
        (res) => {
          if (res.code == 20000) {
            if (res.data) {
              this.initExam(resExamDetail);
            } else {
              this.noPayFileShow = true;
            }
          } else {
            this.$baseMessage(res.data.msg, 'error', 'vab-hey-message-error');
          }
        }
      );
    },
    initExam(res) {
      let examSectionLength = res.data.examSectionVOList.length;
      for (let i = 0; i < examSectionLength; i++) {
        let topicLength =
          res.data.examSectionVOList[i].examSectionTopicVOList.length;
        for (let j = 0; j < topicLength; j++) {
          // this.initAnswer(
          //   this.examDetails.examSectionVOList[i].examSectionTopicVOList[j]
          //     .topicVO
          // );
          this.examAnswers.push({});
        }
      }
      this.examDetails = res.data;
      this.setActive(0, 0);
      this.examCountdown(res.data.duration * 60);
      this.setTopicSpend();
      console.log(JSON.stringify(this.examAnswers));
      var _this = this;
      setTimeout(function () {
        _this.offsetWidth1 = _this.$refs.tasCenter.offsetWidth;
        _this.offsetWidth2 = _this.$refs.tasItem.offsetWidth;
        _this.wInt = Math.ceil(_this.offsetWidth2 / _this.offsetWidth1);
      }, 500);
      this.courseId = res.data.courseId;
      var visits = {
        courseId: this.courseId,
        examId: this.examId,
        examType: 'Exam',
        spend: 0,
      };
      postVisitsExam(visits);
    },
    examCountdown(duration) {
      window.clearTimeout(this.countdownTimeout);
      let that = this;
      this.countdown = secondsToTimeStr(duration);
      if (duration == 0) {
        //this.answerSubmit();
        alert('未设置试卷时间');
        return;
      }
      this.countdownTimeout = setTimeout(function () {
        that.examCountdown(--duration);
      }, 1000);
    },
    setTopicSpend() {
      window.clearTimeout(this.spendTimeout);
      this.examAnswers[this.questionCount + this.topicIndex].spend += 1;
      let that = this;
      this.spendTimeout = setTimeout(function () {
        that.setTopicSpend();
      }, 1000);
    },
    getCourseId() {
      let id = null;
      const key = 'COURSE_ID';
      if (this.$cookies.isKey(key)) {
        id = this.$cookies.get(key);
      }
      return id;
    },
    answerSubmit() {
      window.clearTimeout(this.countdownTimeout);
      window.clearTimeout(this.spendTimeout);
      this.isAnswerSubmit = true;
      //const id = sessionStorage.getItem("COURSE_ID");
      if (this.courseId == null) {
        this.courseId = this.getCourseId();
      }
      let scores = 0;
      let spend = 0;
      let examAnswersLength = this.examAnswers.length;
      for (let i = 0; i < examAnswersLength; i++) {
        scores += this.examAnswers[i].scores;
        spend += this.examAnswers[i].spend;
      }
      let answerReport = {
        refId: this.examId,
        refKind: 'Exam',
        courseId: this.courseId,
        scores: scores,
        spend: spend,
        saveResult: true,
        wrongTopicRefKind: 'Exam',
        wrongTopicRefId: this.examDetails.categoryId,
        topicAnswers: this.examAnswers,
      };
      examTopicResultSubmit(answerReport).then((res) => {
        //examTopicResultSubmit
        if (res.code == 20000) {
          this.$baseMessage('提交成功', 'success', 'vab-hey-message-success');
          this.$router.push({
            path: '/TrueTopic1/' + res.data,
            query: { examId: this.examId },
          });
        } else {
          this.$baseMessage(res.msg, 'error', 'vab-hey-message-error');
          this.isAnswerSubmit = false;
        }
      });
    },
    preTopic() {
      this.topicIndex--;
      if (this.topicIndex < 0) {
        this.active--;
        this.topicIndex =
          this.examDetails.examSectionVOList[this.active].examSectionTopicVOList
            .length - 1;
      }
      this.setActive(this.active, this.topicIndex);
    },
    nextTopic() {
      let sectionTopicVOListLength =
        this.examDetails.examSectionVOList[this.active].examSectionTopicVOList
          .length;
      if (this.topicIndex == sectionTopicVOListLength - 1) {
        this.topicIndex = 0;
        this.active++;
      } else {
        this.topicIndex++;
      }
      this.setActive(this.active, this.topicIndex);
    },
    initAnswer(topicVO) {
      let type = topicVO.type;
      let topicAnswers = {
        answers: [],
        // refId: this.examId,
        // refKind: "Exam",
        scores: 0,
        spend: 0,
        topicId: topicVO.id,
      };
      if (type == 'MULTIPLE_CHOICE') {
        //多选题
        this.initMultipleChoice(topicAnswers, topicVO.questions[0]);
      } else if (type == 'TRUE_OR_FALSE') {
        //判断题
        this.initTrueOrFalse(topicAnswers, topicVO.questions[0]);
      } else if (type == 'SINGLE_CHOICE') {
        //单选题
        this.initSingleChoice(topicAnswers, topicVO.questions[0]);
      } else if (type == 'ESSAY_QUESTION') {
        //问答题
        this.initEssayQuestion(topicAnswers, topicVO.questions[0]);
      } else if (type == 'GAP_FILLING') {
        //填空题
        this.initGapFilling(topicAnswers, topicVO.questions[0]);
      } else if (type == 'MATCHING_ITEM') {
        //匹配题
        this.initMatchingItem(topicAnswers, topicVO.questions[0]);
      } else if (type == 'ACCOUNTING') {
        //会计分录题
        this.initAccounting(topicAnswers, topicVO.questions[0]);
      } else if (type == 'COMPREHENSIVE') {
        //综合题
        this.initComprehensive(topicAnswers, topicVO.questions);
      }
      this.examAnswers.push(topicAnswers);
    },
    initMultipleChoice(topicAnswers, questionData) {
      let questionAnswer = {
        approved: true,
        choicesAnswers: [],
        questionId: questionData.id,
        ret: 'BLANK',
        scores: 0,
        weight: 0,
      };
      topicAnswers.answers.push(questionAnswer);
    },
    initTrueOrFalse(topicAnswers, questionData) {
      let questionAnswer = {
        approved: true,
        choicesAnswers: [],
        questionId: questionData.id,
        ret: 'BLANK',
        scores: 0,
        weight: 0,
      };
      topicAnswers.answers.push(questionAnswer);
    },
    initSingleChoice(topicAnswers, questionData) {
      let questionAnswer = {
        approved: true,
        choicesAnswers: [],
        questionId: questionData.id,
        ret: 'BLANK',
        scores: 0,
        weight: 0,
      };
      topicAnswers.answers.push(questionAnswer);
    },
    initGapFilling(topicAnswers, questionData) {
      let questionAnswer = {
        approved: true,
        fieldsAnswers: [],
        questionId: questionData.id,
        ret: 'BLANK',
        scores: 0,
        weight: 0,
      };
      for (let i = 0; i < questionData.fields.length; i++) {
        questionAnswer.fieldsAnswers.push({
          itemId: -1,
          itemLabel: '',
          itemValue: '',
        });
      }
      topicAnswers.answers.push(questionAnswer);
    },
    initEssayQuestion(topicAnswers, questionData) {
      let questionAnswer = {
        approved: true,
        fieldsAnswers: [],
        questionId: questionData.id,
        ret: 'BLANK',
        scores: 0,
        weight: 0,
      };
      for (let i = 0; i < questionData.fields.length; i++) {
        questionAnswer.fieldsAnswers.push({
          itemId: -1,
          itemLabel: '',
          itemValue: '',
        });
      }
      topicAnswers.answers.push(questionAnswer);
    },
    initMatchingItem(topicAnswers, questionData) {
      let questionAnswer = {
        approved: true,
        matchesAnswers: [],
        questionId: questionData.id,
        ret: 'BLANK',
        scores: 0,
        weight: 0,
      };
      topicAnswers.answers.push(questionAnswer);
    },
    initAccounting(topicAnswers, questionData) {
      let questionAnswer = {
        approved: true,
        accountAnswers: [],
        questionId: questionData.id,
        ret: 'BLANK',
        scores: 0,
        weight: 0,
      };
      topicAnswers.answers.push(questionAnswer);
    },
    initComprehensive(topicAnswers, questions) {
      let questionsLength = questions.length;
      let type = '';
      for (let i = 0; i < questionsLength; i++) {
        type = questions[i].type;
        if (type == 'MULTIPLE_CHOICE') {
          //多选题
          this.initMultipleChoice(topicAnswers, questions[i]);
        } else if (type == 'TRUE_OR_FALSE') {
          //判断题
          this.initTrueOrFalse(topicAnswers, questions[i]);
        } else if (type == 'SINGLE_CHOICE') {
          //单选题
          this.initSingleChoice(topicAnswers, questions[i]);
        } else if (type == 'ESSAY_QUESTION') {
          //问答题
          this.initEssayQuestion(topicAnswers, questions[i]);
        } else if (type == 'GAP_FILLING') {
          //填空题
          this.initGapFilling(topicAnswers, questions[i]);
        } else if (type == 'MATCHING_ITEM') {
          //匹配题
          this.initMatchingItem(topicAnswers, questions[i]);
        } else if (type == 'ACCOUNTING') {
          //会计分录题
          this.initAccounting(topicAnswers, questions[i]);
        }
      }
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    EduHeader,
    NavInfo,
    Topic,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .NewTopicCenter {
      width: 1200px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .purchaseItemButton {
        cursor: pointer;
        width: 120px;
        height: 40px;
        background: #173d7c;
        border-radius: 10px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
      }
      ul {
        li {
          padding-bottom: 20px;
        }
      }
      .FontTitle {
        font-weight: bold;
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      .NewTopicCenterTitle {
        height: 60px;
        overflow: hidden;
        .title-fl {
          float: left;
          font-size: 36px;
          font-weight: 400;
          color: #060606;
          line-height: 60px;
          span {
            color: #173d7c;
          }
        }
      }
      .NewTopicCenter-Title {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
      }
      .NewTopicCenter-tItem {
        height: 40px;
        margin-top: 20px;
        float: right;
        .NewTopicCenter-topic {
          display: block;
          height: 40px;
          background: #636363;
          border: 1px solid #707070;
          border-radius: 20px;
          line-height: 40px;
          padding: 0 25px;
          font-size: 20px;
          font-weight: bold;
          color: #ffffff;
          float: left;
        }
        .NewTopicCenter-time {
          float: right;
          line-height: 40px;
          font-size: 16px;
          span {
            color: #8d0005;
          }
        }
      }
      .page-center {
        height: 32px;
        background: url('~@/assets/TrueTopic/itembg.png') repeat-x left;
        text-align: center;
        margin-top: 30px;
        .itemPage {
          height: 30px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          opacity: 1;
          border-radius: 15px;
          display: inline-block;
          box-sizing: content-box;
          .left {
            float: left;
            background: url('~@/assets/TrueTopic/left.png') no-repeat center;

            cursor: pointer;
            padding: 0 15px;
            border-right: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .right {
            float: right;
            background: url('~@/assets/TrueTopic/right.png') no-repeat center;
            cursor: pointer;
            padding: 0 15px;
            border-left: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .center {
            padding: 0 70px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #575b66;
          }
          &:hover {
            border: 1px solid #8d0005;
            .left {
              border-right: 1px solid #8d0005;
            }
            .right {
              border-left: 1px solid #8d0005;
            }
            .center {
              color: #8d0005;
            }
          }
        }
      }
      .NewTopicCenter-Font {
        margin-top: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .NewTopicCenter-or {
        margin-top: 20px;
        border: 1px solid #d6d6d6;
        border-radius: 6px;
        dl {
          dd {
            .NewTopicCenter-orDdTitle {
              padding: 20px 14px;
              label {
                cursor: pointer;
                display: block;
                position: relative;
                input {
                  float: left;
                  margin-top: 6px;
                }
                div {
                  padding-left: 35px;
                  padding-right: 35px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 24px;
                  color: #060606;
                }
                i {
                  position: absolute;
                  right: 0px;
                  top: 5px;
                }
              }
            }
            .NewTopicCenter-info {
              background: #e8e8e8;

              border-radius: 0px;
              padding: 20px 20px 20px 50px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
            }
          }
        }
      }
      .NewTopicCenter-text {
        margin-top: 20px;
        textarea {
          height: 350px;
          width: 100%;
          border: 0;
          font-size: 16px;
          padding: 5px 0;
          line-height: 24px;
        }
      }
    }
  }
}
.NewTopicCenter-mt35 {
  margin-top: 35px;
  .NewTopicCenter-tas {
    height: 50px;
    overflow: hidden;
    .tas-fl {
      float: left;
      width: 75%;
      overflow: auto;
      font-size: 0;
      white-space: nowrap;
      position: relative;
      dl {
        white-space: nowrap;
        position: relative;
        left: 0;
        float: left;
        transition: all 0.3s ease;
        dd {
          padding: 0 20px;
          display: inline-block;
          border: 1px solid transparent;
          border-bottom: 0;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          font-size: 18px;
          font-weight: 400;
          color: #575b67;
          line-height: 50px;
          cursor: pointer;
          &.on {
            background: #ffffff;
            border: 1px solid #707070;
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
    .tas-fr {
      float: right;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 50px;
    }
    &::after {
      clear: both;
      display: block;
      content: '';
    }
  }
  .NewTopicCenter-content {
    border: 1px solid #707070;
    margin-top: -1px;
    border-radius: 4px;
    padding: 20px 60px;
    .purchaseItemButton {
      cursor: pointer;
      width: 120px;
      height: 40px;
      background: #173d7c;
      border-radius: 10px;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.NewTopicBut {
  padding: 50px 0;
  text-align: center;
  button {
    height: 44px;
    font-size: 16px;
    border-radius: 8px;
    padding: 0 30px;
    margin: 0 10px;
    cursor: pointer;
    color: #333333;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #ffcf00;
    }
  }
}

.tmItem li::after {
  clear: both;
  display: block;
  content: '';
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.NewTopicCenter-item {
  margin-top: 10px;
  dl {
    dd {
      float: left;
      margin-right: 10px;
      height: 30px;
      background: #173d7c;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      padding: 0 20px;
      color: #ffffff;
      cursor: pointer;
      &.on {
        background-color: #ffcf00;
        color: #434343;
      }
    }
  }
  &:after {
    clear: both;
    display: block;
    content: '';
  }
}
.newTopicItemTitle {
  height: 50px;
  border-bottom: 1px solid #707070;
  span {
    display: block;
    float: left;
    border-radius: 4px;
    border: 1px solid #707070;
    font-size: 16px;
    font-weight: 400;
    color: #434343;
    padding: 0 10px;
    line-height: 24px;
    margin-right: 10px;
  }
  .topicIndex {
    background-color: #434343;
    color: #fff;
  }
}
.NewTopicShow {
  background: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 80px 30px 20px 30px;
  .NewTopicInfo {
    border-radius: 6px;
    background: #ffffff;
    width: 830px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    .infoTitle {
      margin: 0 35px;
      padding: 15px 20px;
      background: #173d7c;
      color: #ffffff;
      border-radius: 6px;
      text-align: center;
      .title {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        line-height: 50px;
      }
      .title-font {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        padding-bottom: 10px;
      }
    }
    .NewTopic-ul {
      padding-top: 30px;
      ul {
        li {
          padding: 12px 30px;
          &::after {
            clear: both;
            display: block;
            content: '';
          }
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }
    .close {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background: url('~@/assets/icon-gb.png') no-repeat center;
      background-size: cover;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
.gmBut {
  height: 50px;
  background: #173d7c;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
}
.lh50 {
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #ff4600;
  span {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-left: 15px;
    font-weight: normal;
  }
}
.font-1 {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.font-time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.lh50bf {
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  input {
    float: left;
    margin-top: 20px;
    margin-right: 10px;
  }
}
.borC {
  border-top-left-radius: 0 !important;
}
.tasBut {
  float: left;
  span {
    cursor: pointer;
    width: 24px;
    height: 24px;
    float: left;
    line-height: 24px;
    text-align: center;
    margin-top: 13px;
  }
}
</style>
<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
.showFile {
  background: rgba(67, 67, 67, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 0 50px;
  z-index: 999;
}
.showFile-info {
  width: 630px;
  height: 380px;
  background: #ffffff;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  margin-top: -210px;
}
.showFile-info i {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.showFile-info i img {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.showFile-info-font {
  text-align: center;
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  padding: 75px 30px 20px 30px;
}
.showFile-info-font span {
  color: #ff6600;
}
.showFile-info-button {
  width: 344px;
  height: 50px;
  background: #173d7c;
  border-radius: 6px;
  margin: 0 auto;
  line-height: 50px;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.showFile-info-button:hover {
  background: #10408f;
}
.showFile-info-h {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  text-align: center;
}
.showFile-info-h span {
  border-bottom: 1px solid #666666;
  display: inline-block;
  cursor: pointer;
  line-height: 20px;
}
</style>
