import request from "@/utils/request";

const BASE_PATH = "/front/member/visits/exam";
export function getVisitsExam() {
  return request({
    url: BASE_PATH ,
    method: "get"
  });
}
export function postVisitsExam(data) {
  return request({
    url: BASE_PATH,
    method: "post",
    data
  });
}

